import {GetStaticProps} from 'next';

import Blocks from '@/components/Blocks';
import PageLayout from '@/components/PageLayout';
import {SLUG_HOME} from '@/config/routes';
import {getGlobals, getPage, overrideMeta} from '@/content/cms';
import {Globals, Page} from '@/content/cms/types';
import useFetch from '@/hooks/useFetch';

interface Props {
  globals: Globals;
  page: Page;
}

const fetchProps = async (token?: string) => {
  const globals = await getGlobals(token);
  const page = await getPage(SLUG_HOME, token);
  return {globals, page} as Props;
};

const HomePage = (props: Props) => {
  const {globals, page} = useFetch<Props>(props, fetchProps);
  overrideMeta(page, globals);
  return (
    <PageLayout page={page} globals={globals}>
      <Blocks blocks={page.blocksCollection.items} />
    </PageLayout>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  return {props: await fetchProps()};
};

export default HomePage;
